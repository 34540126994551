.drop_down_wrapper{
  position: relative;
  z-index: 10;
  .drop_down_select{
    position: absolute;
    top: 100%;
    left: -16px;
    background: #fff;
    text-align: center;
    min-width: 50px; 
    max-width: 300px;
    border-radius: 5px;
    box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%), 0 9px 28px 8px rgb(0 0 0 / 5%);
    .drop_down_item{
      // padding-left: 15px;
      // padding-right: 15px;
      // padding-bottom: 6px;
      padding-top: 6px;
      border-bottom: 1px solid #d3d3d3;
      &:hover{
        background-color: #2aabe2;
      }
      &:nth-last-of-type(1){
        border-bottom: 0;
      }
    }
  }
  &.position_right{
    .drop_down_select{
      left: inherit;
      right: 0;
    }
  }
}