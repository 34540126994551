.home {
  height: 700px;
  position: relative;
  padding-top: 90px;
  padding-bottom: 60px;
  overflow: hidden;
  .animate_box{
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
  &.customHeight{
    height: 450px;
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .circleBg{
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, rgba(4,229,195,1) 0%, rgba(4,188,200,1) 50%, rgba(1,158,202,1) 100%);
    position: absolute;
    top: 0;
    left: 0;
    clip-path: circle(50% at 95% 25%);
    z-index: -2;
    &.fullFill{
      clip-path: none;
      left: 0;
      top: 0;
      color: #fff!important;
    }
  }
  .homeContent{
    margin-top: 30px;
    .homeTitle{
      font-size: 30px;
      font-weight: 500;
      // color: var(--title-color-blue);
      margin-bottom: 20px;
    }
    .homeDesc{
      font-size: 18px;
      margin-bottom: 20px;
      span{
        color: var(--title-color-blue);
        font-size: 20px;
        font-weight: 700;
      }
    }
    .homeBtn{
      min-width: 300px;
    }
  }
  .rightPanel{
    .rightPanelImg{
      position: absolute;
    }
    .people{
      top: 170px;
      right: 5%;
      z-index: 6;
    }
    .phone{
      top: 195px;
      right: 10%;
      z-index: 4;
    }
    .barchart{
      top: 80px;
      right: 22%;
      z-index: 5;
    }
    .boardChart1{
      top: 50px;
      z-index: 4;
      right: 32%;
    }
    .boardChart2{
      top: 90px;
      z-index: 3;
      right: 54%;
    }
    .boardChart3{
      top: -5px;
      z-index: 2;
      right: 46%;
    }
  }
  .homeCustomContent{
    margin-top: 70px;
    span{
      color: var(--title-color-blue);
      font-size: 20px;
      font-weight: 700;
    }
  }
}
.full{
  *{
    color: #fff;
  }
  .homeContent{
    margin-top: 60px;
  }
}

.maps{
  padding-top: 60px;
  padding-bottom: 80px;
  h2{
    font-size: 32px;
    color: var(--title-color-blue);
    text-align: center;
    font-weight: 700;
    span{
      font-size: 18px;
      color: #000;
      font-weight: 400;
    }
  }
  .vision{
    font-size: 18px;
    span{
      color: var(--title-color-blue);
      font-size: 24px;
      font-weight: 700;
    }
  }
  .mapsWrapper{
    position: relative;
    width: 700px;
    margin: auto;
    margin-top: 60px;
    margin-bottom: 40px;
    .marker{
      position: absolute;
      &.markerUsa {
        top: 15%;
        left: 15%;
        span{
          font-weight: 700;
          position: absolute;
          left: -20px;
          bottom: -15px;
          color: #838383;
        }
      }
      &.markerGermany{
        top: 23%;
        left: 48%;
        span{
          font-weight: 700;
          position: absolute;
          left: 22px;
          bottom: -5px;
          color: #838383;
        }
      }
      &.markerKorea{
        top: 43%;
        right: 8%;
        span{
          font-weight: 700;
          position: absolute;
          left: 15px;
          bottom: -5px;
          color: #838383;
        }
      }
      &.markerMyanmar{
        top: 56%;
        right: 20%;
        span{
          font-weight: 700;
          position: absolute;
          left: -76px;
          top: -6px;
          color: #838383;
        }
      }
      &.markerCambodia{
        top: 62%;
        right: 20%;
        span{
          font-weight: 700;
          position: absolute;
          left: -80px;
          bottom: -5px;
          color: #838383;
        }
      }
      &.markerThailand{
        top: 59.2%;
        right: 18.5%;
        span{
          font-weight: 700;
          position: absolute;
          left: 10px;
          bottom: -25px;
          color: #838383;
        }
      }
      &.markerVietnam{
        top: 59.5%;
        right: 17.5%;
        span{
          font-weight: 700;
          position: absolute;
          left: 12px;
          bottom: -5px;
          color: #838383;
        }
      }
      &.markerVietnam2{
        top: 54%;
        right: 17.5%;
      }
    }
  }
}

.services{
  padding-top: 45px;
  padding-bottom: 40px;
  position: relative;
  .bg_gradient{
    position: absolute;
    left: 0;
    height: 50%;
    width: 100%;
  }
  .bg_gradient_top{
    top: 0;
    background: linear-gradient(180deg, rgba(4,229,195,0) 0, rgba(4,229,195,0.5) 50%, rgba(1,161,201,1) 100%);
  }
  .bg_gradient_bottom{
    top: 50%;
    background: linear-gradient(180deg,  rgba(1,161,201,1) 0%, rgba(1,161,201,0.5) 50%, rgba(1,161,201,0) 100%);
  }
  h2{
    color: var(--title-color-dark-blue);
    font-size: 28px;
    font-weight: 500;
  }
  // h3{
  //   color: var(--title-color-dark-blue);
  //   font-size: 28px;
  //   font-weight: 500;
  //   margin-bottom: 40px;
  // }
  .title{
    margin-bottom: 30px;
  }
}

.news{
  padding-top: 40px;
  padding-bottom: 80px;
  h3{
    color: var(--title-color-dark-blue);
    font-size: 28px;
    font-weight: 500;
    margin-bottom: 20px;
  }
}

.contact{
  background-color: #44B5E5;
}

@keyframes markerMushroom{
  from {
    transform: scale3d(1, 1, 1);
    opacity: 1;
  }

  30% {
    transform: scale3d(0.75, 1.25, 1);
    opacity: 1;
  }

  40% {
    transform: scale3d(1.25, 0.75, 1);
    opacity: 1;
  }

  50% {
    transform: scale3d(0.85, 1.15, 1);
    opacity: 1;
  }

  65% {
    transform: scale3d(1.05, 0.95, 1);
    opacity: 1;
  }

  75% {
    transform: scale3d(0.95, 1.05, 1);
    opacity: 1;
  }

  to {
    transform: scale3d(1, 1, 1);
    opacity: 1;
  }
}

@media only screen and (min-width: 768px){
  .home{
    .circleBg{
      clip-path: circle(50% at 95% 35%);
      -webkit-clip-path: circle(50% at 95% 35%);
    }
  }
}
@media only screen and (min-width: 980px){
  .home{
    .circleBg{
      clip-path: circle(50% at 95% 20%);
      -webkit-clip-path: circle(50% at 95% 20%);
    }
  }
}
@media only screen and (min-width: 1440px){
  .home{
    .circleBg{
      clip-path: circle(50% at 95% 6%);
      -webkit-clip-path: circle(50% at 95% 6%);
    }
  }
}
@media only screen and (min-width: 1600px){
  .home{
    .circleBg{
      clip-path: circle(50% at 95% 4%);
      -webkit-clip-path: circle(50% at 95% 4%);
    }
  }
}
@media only screen and (min-width: 1920px){
  .home{
    .circleBg{
      clip-path: circle(50% at 95% -8%);
      -webkit-clip-path: circle(50% at 95% -8%);
    }
  }
}

// @media only screen and (max-width: 576px){
//   .maps{
//     h3{
//       font-size: 30px;
//       span{
//         font-size: 18px;
//       }
//     }
//     .vision{
//       font-size: 18px;
//       span{
//         font-size: 24px;
//       }
//     }
//   }
// }

@media only screen and (max-width: 991px){
  .home{
    padding-top: 30px;
    height: auto;
    &.customHeight{
      height: auto;
    }
    .rightPanel{
      margin-top: 60px;
      .barchart{
        right: 28%;
      }
      .boardChart1{
        right: 40%;
      }
      .boardChart2{
        right: 75%;
      }
      .boardChart3{
        right: 62%;
      }
    }
  }
}
@media only screen and (max-width: 576px){
  .home{
    padding-top: 30px;
    height: auto;
    &.customHeight{
      height: auto;
      // padding-top: 40px;
      // padding-bottom: 40px;
    }
    .circleBg{
      clip-path: none;
      &.fullFill{
        left: -15px;
        width: 150%;
        clip-path: none;
      }
    }
    .rightPanel{
      padding-top: 100%;
      margin-top: 20px;
      .people{
        top: 170px;
        right: 0;
        z-index: 6;
      }
      .phone{
        top: 195px;
        right: 40px;
        z-index: 4;
      }
      .barchart{
        top: 80px;
        right: 100px;
        z-index: 5;
      }
      .boardChart1{
        top: 50px;
        z-index: 4;
        right: 143px;
      }
      .boardChart2{
        top: 90px;
        z-index: 3;
        right: 265px;
      }
      .boardChart3{
        top: -5px;
        z-index: 2;
        right: 205px;
      }
    }
    .homeContent{
      margin-top: 0;
      .homeTitle{
        font-size: 24px;
      }
      .homeDesc{
        font-size: 16px;
      }
    }
  }
  .maps{
    width: 100%;
    h2{
      font-size: 24px;
      span{
        font-size: 16px;
      }
    }
    .vision{
      font-size: 16px;
      span{
        font-size: 20px;
      }
    }
    .mapsWrapper{
      position: relative;
      width: 100%;
      margin: auto;
      margin-top: 60px;
      margin-bottom: 40px;
      .marker{
        span{
          font-size: 14px;
        }
        &.markerUsa{
          top: 4%;
          left: 14%;
          transform: scale(0.8);
          -webkit-transform: scale(0.8);
          -moz-transform: scale(0.8);
          -ms-transform: scale(0.8);
        }
        &.markerGermany{
          top: 14%;
          left: 45%;
          transform: scale(0.8);
          -webkit-transform: scale(0.8);
          -moz-transform: scale(0.8);
          -ms-transform: scale(0.8);
        }
        &.markerKorea{
          top: 35%;
          right: 6%;
          span{
            left: 15px;
            bottom: -5px;
          }
        }
        &.markerMyanmar{
          top: 52%;
          right: 18.5%;
          span{
            font-weight: 700;
            position: absolute;
            left: -76px;
            top: -6px;
            color: #838383;
          }
        }
        &.markerCambodia{
          top: 58%;
          right: 18.5%;
          span{
            font-weight: 700;
            position: absolute;
            left: -80px;
            bottom: -5px;
            color: #838383;
          }
        }
        &.markerThailand{
          top: 54.2%;
          right: 18%;
          span{
            font-weight: 700;
            position: absolute;
            left: 5px;
            bottom: -25px;
            color: #838383;
          }
        }
        &.markerVietnam{
          top: 54.5%;
          right: 17%;
          span{
            font-weight: 700;
            position: absolute;
            left: 8px;
            bottom: -5px;
            color: #838383;
          }
        }
        &.markerVietnam2{
          top: 49.5%;
          right: 17%;
        }
      }
    }
  }
  
  .services{
    padding-bottom: 0;
    padding-top: 0;
    h2{
      font-size: 24px;
    }
    h5{
      font-size: 18px;
    }
  }

  .news{
    h3{
      font-size: 24px;
    }
  }
}

@media only screen and (max-width: 480px){
  .maps{
    .mapsWrapper{
      .marker{
        &.markerUsa{
          top: 6%;
          left: 15%;
          transform: scale(0.8);
          -webkit-transform: scale(0.8);
          -moz-transform: scale(0.8);
          -ms-transform: scale(0.8);
        }
        &.markerGermany{
          top: 16%;
          left: 46%;
          transform: scale(0.8);
          -webkit-transform: scale(0.8);
          -moz-transform: scale(0.8);
          -ms-transform: scale(0.8);
        }
        &.markerKorea{
          top: 37%;
          right: 6%;
          span{
            left: 15px;
            bottom: -5px;
          }
        }
        &.markerMyanmar{
          top: 52%;
          right: 18.5%;
          span{
            font-weight: 700;
            position: absolute;
            left: -76px;
            top: -6px;
            color: #838383;
          }
        }
        &.markerCambodia{
          top: 58%;
          right: 18.5%;
          span{
            font-weight: 700;
            position: absolute;
            left: -80px;
            bottom: -5px;
            color: #838383;
          }
        }
        &.markerThailand{
          top: 54.2%;
          right: 18%;
          span{
            font-weight: 700;
            position: absolute;
            left: 5px;
            bottom: -25px;
            color: #838383;
          }
        }
        &.markerVietnam{
          top: 54.5%;
          right: 17%;
          span{
            font-weight: 700;
            position: absolute;
            left: 8px;
            bottom: -5px;
            color: #838383;
          }
        }
        &.markerVietnam2{
          top: 49.5%;
          right: 17%;
        }
      }
    }
  }
}

@media only screen and (max-width: 380px){
  .maps{
    .mapsWrapper{
      .marker{
        &.markerUsa{
          top: 4%;
          left: 14%;
          transform: scale(0.8);
          -webkit-transform: scale(0.8);
          -moz-transform: scale(0.8);
          -ms-transform: scale(0.8);
        }
        &.markerGermany{
          top: 14%;
          left: 45%;
          transform: scale(0.8);
          -webkit-transform: scale(0.8);
          -moz-transform: scale(0.8);
          -ms-transform: scale(0.8);
        }
        &.markerKorea{
          top: 35%;
          right: 6%;
          span{
            left: 15px;
            bottom: -5px;
          }
        }
        &.markerMyanmar{
          top: 52%;
          right: 18.5%;
          span{
            font-weight: 700;
            position: absolute;
            left: -76px;
            top: -6px;
            color: #838383;
          }
        }
        &.markerCambodia{
          top: 58%;
          right: 18.5%;
          span{
            font-weight: 700;
            position: absolute;
            left: -80px;
            bottom: -5px;
            color: #838383;
          }
        }
        &.markerThailand{
          top: 54.2%;
          right: 18%;
          span{
            font-weight: 700;
            position: absolute;
            left: 5px;
            bottom: -25px;
            color: #838383;
          }
        }
        &.markerVietnam{
          top: 54.5%;
          right: 17%;
          span{
            font-weight: 700;
            position: absolute;
            left: 8px;
            bottom: -5px;
            color: #838383;
          }
        }
        &.markerVietnam2{
          top: 49.5%;
          right: 17%;
        }
      }
    }
  }
}

@media only screen and (max-width: 340px){
  .home{
    .rightPanel{
      padding-top: 115%;
    }
  }
}