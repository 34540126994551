.header{
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  left: 0;
  background: #fff;
  z-index: 10;
  .items{
    height: 66px;
  }
  .menuItemBorder{
    height: 4px;
    width: 100%;
    background-color: rgb(29, 123, 163);
    position: absolute;
    bottom: 0;
    left: 0;
  }
  .mb_btn{
    outline: none;
    border: none;
    position: relative;
    z-index: 10;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    cursor: pointer;
    width: 50px;
    height: 50px;
    background: transparent;
    &:active{
      background-color: #d4d4d4;
    }
  }
  .logo{
    position: relative;
    z-index: 10;
  }
  &.isScroll{
    -webkit-box-shadow: 0px 3px 4px 0px rgba(201,201,201,1);
    -moz-box-shadow: 0px 3px 4px 0px rgba(201,201,201,1);
    box-shadow: 0px 3px 4px 0px rgba(201,201,201,1);
  }
}

.menuItems{
  margin: 0 10px;
  padding: 0 15px;
  cursor: pointer;
  height: 66px;
  line-height: 66px;
  color: var(--title-color-blue);
  text-decoration: none;
}
.global{
  line-height: 80px;
}

.mb_menu{
  position: fixed;
  top: 50px;
  right: 0;
  width: 100%;
  z-index: 10;
  padding-top: 15px;
  .menuItems{
    padding: 0;
    margin: 0;
    font-size: 20px;
    font-weight: 500;
    color: #fff;
    height: 50px;
    line-height: 50px;
  }
}
.mb_menu_background{
  position: fixed;
  top: 50px;
  right: 0;
  width: 100%;
  height: 250px;
  background: #29abe2;
  z-index: 9;
}

@media only screen and (max-width: 991px){
  .mb_menu{
    .items{
      height: 50px;
      margin-bottom: 10px;
      margin-top: 10px;
      // &:active{
      //   background-color: rgba(0,0,0,0.3);
      // }
    }
  }
  .header{
    .items{
      height: 50px;
    }
    .logo{
      height: 50px;
    }
  }
}

@media only screen and (max-width: 576px){
  .mb_menu{
    width: 100%;
  }
  .mb_menu_background{
    width: 100%;
  }
  .header{
    .logo{
      img{
        width: 150px!important;
      }
    }
  }
}