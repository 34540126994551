.custom{
  visibility: inherit;
  position: absolute;
  inset: 0px;
  box-sizing: border-box;
  padding: 0px;
  border: none;
  margin: auto;
  display: block;
  width: 0px;
  height: 0px;
  min-width: 100%;
  max-width: 100%;
  min-height: 100%;
  max-height: 100%;
  top: 0;
  left: 0;
}
.optimizeImg{
  visibility: inherit;
  position: absolute;
  top: 0;
  left: 0;
  inset: 0px;
  box-sizing: border-box;
  padding: 0px;
  border: none;
  margin: auto;
  display: block;
  width: 0px;
  height: 0px;
  min-width: 100%;
  max-width: 100%;
  min-height: 100%;
  max-height: 100%;
  object-fit: contain;
  &.cover{
    object-fit: cover;
  };
  &.contain{
    object-fit: contain;
  };
  &.objectNone{
    object-fit: none;
  };
}

.originalImg{
  max-width: 100%;
  display: block;
  margin: 0;
  border: none;
  padding: 0;
  opacity: 0;
}