.submitButton {
  top: 12px;
  right: 60px;
  &:focus {
    box-shadow: none;
  }
}
.searchIcon {
  font-size: 20px;
  color: #707070;
}
.searchForm {
  width: 200px;
  border: 1px solid rgb(41, 171, 226)
}

@media only screen and (max-width: 768px) {
  .searchForm {
    width: 50%;
  }
  .submitButton {
    top: 12px;
    right: 25%;
  }
}
